import React from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import promoImgOne from "../images/promo_1.webp";
import promoImgTwo from "../images/promo_2.webp";

const Promo = () => {
    const { language } = useI18next();

    // Only show the divs if the language is Estonian ('et')
    if (language !== 'et') {
        return null;
    }

    return (
        <div
            className="w-full max-w-5xl m-auto h-full max-h-full md:max-h-300 xl:max-h-400 mt-0 md:mt-6 flex justify-between flex-col md:flex-row pb-10 px-2 md:px-0 md:pb-0"
        >
            <div
                className="pt-0 w-full max-h-300 xl:max-h-400 bg-white flex justify-center rounded-xl overflow-hidden mr-0 md:mr-8"
            >
                <a
                    className={"w-full"}
                    href={"https://www.facebook.com/groups/821843821278026/"}
                    target={"_blank"}
                    rel="noreferrer"
                >
                    <img className={"w-full h-full"} src={promoImgOne} alt="promo"/>
                </a>
            </div>
            <div className="max-w-lg w-full bg-white rounded-xl flex justify-center overflow-hidden m-0 mt-6 md:mt-0">
                <a
                    className={"w-full"}
                    href={"https://www.facebook.com/simeonsidieet"}
                    target={"_blank"}
                    rel="noreferrer"
                >
                    <img className={"w-full h-full"} src={promoImgTwo} alt="promo"/>
                </a>
            </div>
            <div id="fb-root"></div>
        </div>
    );
};

export default Promo;
